import { useEffect } from "react"
import { mapSrollToSection } from '../../shared/helpers'

export const Main = () => {

  useEffect(() => {
    const hash = window.location.hash.replace('#', '');

    mapSrollToSection(hash);
  }, []);


  return (
    <div className="body">
            <main id="main" className="main">
                <header id="header" className="header px-0 px-xs-8 px-sm-0 px-md-24 px-lg-48 px-xl-80">
                    <div className="container-fixed d-fl ai-c w-100">
                        <a href="#main" className="header__logo fade-in-top-small a-dr-05 scroll-main">
                            <svg width="48" height="48" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M51.1835 17.4927C50.9833 31.7988 26.0349 48.0048 14.9708 36.9412C3.90656 25.8774 20.1132 0.927982 34.4186 0.727808C43.809 0.596409 51.3149 8.10232 51.1835 17.4927Z" fill="white"/>
                                <path d="M9.41935 13.6188C17.3816 11.3753 21.3628 10.2535 24.1338 10.3392C33.6406 10.6333 41.2786 18.2714 41.5727 27.7781C41.6584 30.5491 40.5705 34.4102 38.3946 42.1323V42.1323C37.2969 46.0283 36.748 47.9763 36.2551 48.6933C34.3784 51.4236 31.3742 51.908 28.7345 49.9058C28.0413 49.38 26.7335 47.4449 24.1178 43.5746C19.926 37.3724 14.8647 32.3805 7.76464 27.7493C3.9662 25.2716 2.06698 24.0328 1.50947 23.2893C-0.401212 20.7412 0.0606961 17.7191 2.64526 15.8581C3.39939 15.315 5.40605 14.7496 9.41935 13.6188V13.6188Z" fill="#7E50FB"/>
                                <path d="M51.1835 17.4927C50.9833 31.7988 26.0349 48.0048 14.9708 36.9412C3.90656 25.8774 20.1132 0.927982 34.4186 0.727808C43.809 0.596409 51.3149 8.10232 51.1835 17.4927Z" fill="#FFD000"/>
                            </svg>
                        </a>

                        <input id="globalMenu" type="checkbox" className="header-menu-control"/>
                        <label htmlFor="globalMenu" className="burger-menu ml-24 fade-in-top-small a-dr-05 a-dl-02 scroll-main"><span className="menu-line"></span></label>
                        <div id="sideManu" className="menu-container ai-c">
                            <label title="Назад" htmlFor="globalMenu" className="link-arrow link-arrow--white link-arrow--left d-md-none">
                                <span className="icon-back"></span>
                            </label>
                            <div className="d-fl fl-col fl-md-row ai-md-c w-100 jc-sm-c rg-24 cg-24 cg-lg-40 fade-in-top-small a-dr-05 a-dl-04 scroll-main">
                                <a className="menu-container__link menu ml-24" href="#about">Общая информация</a>
                                <a className="menu-container__link menu ml-24" href="#installation">Установка и эксплуатация</a>
                                <a className="menu-container__link menu" href="#cost">Стоимость</a>

                                <div className="d-fl fl-col rg-16 pt-24 d-md-none">
                                    <a href="./privacy-policy.pdf" className="menu-container__link docs">Политика Конфиденциальности</a>
                                    <a href="./rules.pdf" className="menu-container__link docs">Правила поведения в транспортном средстве</a>
                                    <a href="./personal-data.pdf" className="menu-container__link docs">Согласие на обработку персональных данных</a>
                                    <a href="./user_agreement.pdf" className="menu-container__link docs">Пользовательское соглашение мобильного приложения</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>


                <div className="main-container d-fl fl-col jc-c ai-c">
                    <h1 className="main__header mr-md-80 slide-left a-dr-07 scroll-main">Челнок</h1>
                </div>
            </main>

            <div className="d-fl fl-col ai-c px-16 px-xs-24 px-lg-40 px-xl-80">
                 <section id="about" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll">
                    <div className="about container-fixed">
                        <h2 className="h2 t-c fade-in-top-small a-dr-06 js-scroll">Описание функциональных характеристик</h2>

                        <p className="services__text mt-20">
                            Программное обеспечение организации пассажирских и грузовых перевозок по требованию «Челнок» (далее – ПО) предназначено для управления и организации перевозок пассажиров общественным, корпоративным и социальным транспортом; перевозок грузов технологическим транспортом и внутризаводской логистики. </p>
                        <p className="services__text mt-20">ПО состоит из:</p>

                        <ul className="services__text mt-12">
                            <li className="services__text mt-4 ">
                                ядра для управления перевозками пассажиров общественным транспортом по требованию (далее – Челнок Микротранзит);
                            </li>
                            <li className="services__text mt-4 ">
                                программных модулей: Челнок Карго, Челнок Корпоративное (Социальное) такси.
                            </li>
                        </ul>

                        <p className="services__text mt-20">
                            ПО позволяет в единой системе:
                        </p>
                        <ul className="services__text mt-12">
                            <li className="services__text mt-4">консолидировать заявки на перевозку (далее – ТС) в едином интерфейсе;</li>
                            <li className="services__text mt-4">автоматизировать процесс приема заявок и назначения ТС для их выполнения с целью снижения нагрузки на диспетчера;</li>
                            <li className="services__text mt-4">отслеживать и контролировать этапы выполнения заявок, просматривать аналитику и отчетность в едином интерфейсе;</li>
                            <li className="services__text mt-4">осуществлять взаимодействие пассажира/заказчика и водителя через мобильные приложения по вопросу выполнения заявки;</li>    
                            <li className="services__text mt-4">наглядно управлять работой всего автопарка, осуществлять мониторинг работы ТС в реальном времени путем интеграции с мобильными приложениями пассажира/заказчика и водителя и провайдерами телеметрии;</li>    
                            <li className="services__text mt-4">повысить эффективность использования автопарка благодаря детальной аналитике и отчетам;</li>    
                            <li className="services__text mt-4">контролировать и оценивать работу водителей, управлять их загруженностью и сменами.</li>    
                        </ul>
                    </div>
                </section>     
                      
                <section id="installation" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll">
                    <div className="mobile-app container-fixed">
                        <h2 className="h2 t-c fade-in-top-small a-dr-06 js-scroll mt-24 mb-24">Информация для установки и эксплуатации</h2>
                        <p className="services__text mt-12">
                            <ul>
                                <li className='mt-4 ml-24'>
                                    <a class="menu-container__link menu" href="/files/chelnock-microtransit.pdf" download="Инструкция Челнок Микротранзит" rel="noreferrer" type="application/pdf">Инструкции для установки и эксплуатации Челнок Микротранзит</a>
                                </li>
                                <li className='mt-4 ml-24'>
                                    <a class="menu-container__link menu"  href="/files/chelnock-cargo.pdf" download="Инструкция Челнок Карго" type="application/pdf">Инструкции для установки и эксплуатации Челнок Карго</a>
                                </li>
                                <li className='mt-4 ml-24'>
                                    <a class="menu-container__link menu" href="/files/chelnock-corporate.pdf" download="Инструкция Челнок Корп" type="application/pdf">Инструкции для установки и эксплуатации Челнок Корпоративное (Социальное) такси</a>
                                </li>
                            </ul>
                        </p>
                       </div>
                </section>

                <section id="cost" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll">
                    <div id="driver-manual" className="mobile-app-driver container-fixed mt-24">
                        <h2 className="h2 t-c fade-in-top-small a-dr-06 js-scroll mt-24 mb-24">Стоимость лицензии</h2>
                        <p className="services__text mt-20">Доступ к ПО предоставляется на основании неисключительной (простой) лицензии.</p>
                        <p className="services__text mt-20">Стоимость лицензии зависит от сочетания факторов:</p>
                        <ul className="services__text mt-12">
                            <li className="mt-4 ml-24">объем адаптации/модернизации ПО, необходимых для внедрения;</li>
                            <li className="mt-4 ml-24">условия технической поддержки.</li>
                        </ul>
                        <p className="services__text mt-12">
                            Стоимость лицензии и условия оплаты, объем прав и условия предоставления лицензий, в том числе период разрешенного использования, территория использования, способы использования и возможность предоставления сублицензий определяются сторонами в соответствующем лицензионном договоре.
                       </p>
                        <p className="services__text mt-12">
                           Стоимость лицензии и условия оплаты рассчитывается индивидуально и предоставляется по запросу в зависимости от выбранного набора сервисов и модулей. За более подробной информацией просим обращаться по электронной почте: microtransit@kamaz.digital и по телефону +7 (499) 290-24-29.
                        </p>
                    </div>
                </section>
                <footer id="footer" className="footer mt-24">
                    <div className="d-fl fl-col fl-sm-row jc-sb cg-16 rg-32 mt-40">
                        <div className="w-100 w-sm-40 fade-in-bottom-small a-dr-05 js-scroll">
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px"
                            }}>
                                <svg width="18" height="18" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M51.1835 17.4927C50.9833 31.7988 26.0349 48.0048 14.9708 36.9412C3.90656 25.8774 20.1132 0.927982 34.4186 0.727808C43.809 0.596409 51.3149 8.10232 51.1835 17.4927Z" fill="white"/>
                                    <path d="M9.41935 13.6188C17.3816 11.3753 21.3628 10.2535 24.1338 10.3392C33.6406 10.6333 41.2786 18.2714 41.5727 27.7781C41.6584 30.5491 40.5705 34.4102 38.3946 42.1323V42.1323C37.2969 46.0283 36.748 47.9763 36.2551 48.6933C34.3784 51.4236 31.3742 51.908 28.7345 49.9058C28.0413 49.38 26.7335 47.4449 24.1178 43.5746C19.926 37.3724 14.8647 32.3805 7.76464 27.7493C3.9662 25.2716 2.06698 24.0328 1.50947 23.2893C-0.401212 20.7412 0.0606961 17.7191 2.64526 15.8581C3.39939 15.315 5.40605 14.7496 9.41935 13.6188V13.6188Z" fill="#7E50FB"/>
                                    <path d="M51.1835 17.4927C50.9833 31.7988 26.0349 48.0048 14.9708 36.9412C3.90656 25.8774 20.1132 0.927982 34.4186 0.727808C43.809 0.596409 51.3149 8.10232 51.1835 17.4927Z" fill="#FFD000"/>
                                </svg>
                                <h1 className="footer__header t-m ml-20">Челнок</h1>
                            </div>
                            <p className="mb-20">
                                Программное обеспечение организации пассажирских и грузовых перевозок по требованию
                            </p>
                            <p className="t-xs">Продукт KAMAZ Digital</p>
                            <p className="t-xs mt-4 mb-20">© ООО «ЦП КАМАЗ», 2024. Все права защищены </p>
                        </div>

                        <div className="d-fl fl-col w-100 w-sm-40 fade-in-bottom-small a-dr-05 js-scroll">
                            <h4 className="footer__header t-m mb-20">Автоматизируем транспорт</h4>
                            <a
                                href="https://drt.chelnok.space/"
                                target="_blank"
                                className="footer__link t-xs d-inl-b"
                            >
                                Микротранзит: общественный транспорт
                            </a>
                            <a
                                href="https://cargo.chelnok.space/"
                                target="_blank"
                                className="footer__link t-xs d-inl-b mt-12"
                            >
                                Карго: технологический транспорт
                            </a>
                            <a
                                href="https://corptaxi.chelnok.space/ "
                                target="_blank"
                                className="footer__link t-xs d-inl-b mt-12"
                            >
                                Корпоративное такси: поездки для сотрудников
                            </a>
                        </div>

                        <div className="w-100 w-sm-auto fade-in-bottom-small a-dr-05 js-scroll">
                            <h4 className="footer__header t-m mb-20">Контакты</h4>
                                <p  className="footer__link t-sm">
                                    +7 (499) 290-24-29
                                </p>

                            <a href="mailto:microtransit@kamaz.digital&subject=Обратная связь Челнок" className="d-fl ai-c cg-12 mt-12">
                                <p target="blank" className="footer__link t-sm">
                                    microtransit@kamaz.digital
                                </p>
                            </a>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
  ) 
}
